@import 'globals';

/* UserInfoPopup.scss */

$popup-background: #fff;
$popup-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
$popup-width: 320px;
$popup-padding: 5px;
$popup-radius: 8px;
$text-color: #666;
$transition-time: 0.2s;

/* UserInfoPopup.scss */

$user-avatar-size: 60px;

.hoverable-user {
  position: relative;
  cursor: pointer;

  .user-info-popup {
    position: absolute;
    top: -10%;
    left: 0;
    z-index: 10;
    width: $popup-width;
    background-color: $popup-background;
    box-shadow: $popup-shadow;
    padding: $popup-padding;
    border-radius: $popup-radius;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-time ease, visibility $transition-time ease, transform $transition-time ease;
    transform: translateY(10px);

    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: 16px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent $popup-background transparent;
    }

    .user-avatar {
      width: $user-avatar-size;
      height: $user-avatar-size;
      border-radius: 50%;
      position: absolute; // Keep it positioned to the right from the start
      left: calc(100% - 65px); // Initially placed outside the popup
      top: 50%;
      transform: translateY(-50%);
      object-fit: cover;
      transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
      transform-origin: 70px 350px;

      &:hover {
        width: 250px;
        height: 250px;
        transform: translateY(-50%) scale(1.4);
        z-index: 20;
      }
    }

    .user-avatar-top {
      @extend .user-avatar;
      transform-origin: 50px -100px;
    }


    .user-icon {
      width: 0px;
      height: 0px;
      border-radius: 50%;
      margin-right: 12px;
      float: left;
      object-fit: cover;
      color: #ccc;
    }

    .user-details {
      margin-left: 0px;
      margin-right: 60px;

      h4 {
        margin: 0;
        font-size: 1em;
        font-weight: bold;
        color: $text-color
      }

      p {
        margin: 4px 0;
        font-size: 0.9em;
        color: #666;
      }
    }
  }
  .user-info-popup-right {
    @extend .user-info-popup;
    left: -90%;
  }

  &:hover .user-info-popup {
    opacity: 1;
    visibility: visible;
  }
}

.hoverable-user:hover .user-info-popup {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); // Reset transform when visible
}

table {
  margin-bottom: 40px;
}

.card.overflow-visible {
  overflow: visible;
}
.overflow-visible .card__content, .news-list .card__content {
  overflow: visible;
}
