@import 'globals';
table.sticky-table {
  position: relative;
}
tr.sticky-table-header {
  th {
    position: fixed;
    top: 0;
  }
}

.top-modal {
  z-index: 10000000;
}

.hint-training-date {
  margin: 0;
  padding: 5px 10px 0 30px;
  color: #e20414;
  font-size: 11px;
}
